<template>
  <div id="roles-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Setting Name</h6>
                  </label>
                  <v-text-field
                    v-model="Name"
                    :rules="LomTrainingSettingNameRules"
                    placeholder="Setting Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span>Setting Display Text
                    </h6>
                  </label>
                  <v-text-field
                    v-model="DisplayText"
                    :rules="SettingDisplayTextRules"
                    placeholder="Display Text"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span>Setting Value</h6>
                  </label>
                  <v-text-field
                    v-model="Value"
                    :rules="SettingValueRules"
                    placeholder="Display Text"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
    record: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      ResultFlag: false,
      SubmitFlag: false,
      row: {},
      PageTitle: "Edit-LO Training Setting",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      Name: "",
      LomTrainingSettingNameRules: [(v) => !!v || "Name is required"],

      DisplayText: "",
      SettingDisplayTextRules: [(v) => !!v || " DisplayText is required"],

      value: "",
      SettingValueRules: [(v) => !!v || "value is required"],

      UnitName: "",
      TrainingEventUnitNameRules: [
        (v) => !!v || "Training Event Unit Name is required",
      ],
    };
  },

  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        console.log("recordData=" + JSON.stringify(this.recordData));
      }
      this.pageData();
      this.getEventDetails();
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.StartupLoadingFlag = false;
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    pageData() {
      console.log("pageData called");
      this.StartupLoadingFlag = true;
      this.getJciYearCodeOptions();
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },

    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    refreshPageData() {
      this.pageData();
      this.getEventDetails();
      console.log("refreshPageData called");
      var record = this.record;
      console.log({ record });
      this.row = record;
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "Training Events",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },

    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        /*
        var MemberTypeId = this.MemberTypeId;
        console.log("MemberTypeId=" + MemberTypeId);

        // Get Title details

        var TitleId = this.TitleCode;
        var TitleIndex = this.TitleCodeOptions.map((e) => e.value).indexOf(
          TitleId
        );
        console.log("TitleId=" + TitleId + ", TitleIndex=" + TitleIndex);
        var TitleName = this.TitleCodeOptions[TitleIndex].text;
        console.log("TitleName=" + TitleName);
        */
        var htmlTxt = "";
        // htmlTxt += "<br/> Title <b> " + TitleName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      var EventId = this.record.LomTrainingSettingId;
      console.log("EventId=" + EventId);
      if (validate1) {
        var upload = {
          UserInterface: 1,
          LomTrainingSettingId: EventId,
          Name: this.Name,
          DisplayText: this.DisplayText,
          Value: this.Value,
        };
        console.log({ upload });

        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training-setting/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },

    getEventDetails() {
      console.log("getEventDetails is called");

      var EventId = this.record.LomTrainingSettingId;
      console.log("EventId=" + EventId);

      if (EventId != "") {
        var upload = {
          UserInterface: 1,
          LomTrainingSettingId: EventId,
        };
        console.log({ upload });

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training-setting/show";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            console.log("records=" + records);

            if (flag == 1) {
              thisIns.Name = records.Name;
              thisIns.DisplayText = records.DisplayText;
              thisIns.Value = records.Value;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (EventId == "") {
          message += "City code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-event-faculty {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>